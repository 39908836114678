import { PatientService } from '@/services/patient.service';
export default {
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showDialog: false,
      patientData: {},
      buttonDisabled: false,
      alert: {
        show: false,
        message: ''
      },
      removalReasons: [{
        value: 'Repeat Credit Card Offender',
        text: 'Repeat Credit Card Offender'
      }, {
        value: 'Offensive Communication',
        text: 'Offensive Communication'
      }, {
        value: 'Dishonest & Liability',
        text: 'Dishonest & Liability'
      }],
      chosenReason: '',
      removalNotes: '',
      toRefund: []
    };
  },
  watch: {
    async showDialog(show) {
      if (show) {
        try {
          this.buttonDisabled = true;
          const patientData = await PatientService.getPatient(this.rowData.id);
          this.patientData = patientData.data;
          this.buttonDisabled = false;
        } catch (err) {
          this.alert.show = true;
          this.alert.message = err;
          this.buttonDisabled = false;
        }
      } else {
        this.buttonDisabled = false;
        this.alert.show = false;
        this.patientData = {};
        this.chosenReason = '';
        this.removalNotes = '';
      }
    }
  },
  methods: {
    async onBanPatient() {
      try {
        this.buttonDisabled = true;
        if (!this.chosenReason || !this.removalNotes) {
          throw 'You must fill in all the fields.';
        }
        const dataToSubmit = {
          reason: this.chosenReason,
          notes: this.removalNotes
        };
        const {
          data
        } = await PatientService.banPatient(dataToSubmit, this.patientData.id);
        if (data) {
          setTimeout(() => {
            this.$emit('refresh-self');
          }, 3000);
          this.showDialog = false;
        }
      } catch (err) {
        this.alert.message = err;
        this.alert.show = true;
        this.buttonDisabled = false;
      }
    },
    getDate(date) {
      if (date == '-') {
        return '-';
      }
      return new Date(date * 1000).toDateString();
    }
  }
};